<template>
  <v-dialog v-model="dialog" persistent :max-width="maxWidth">
    <template #activator="{ on }">
      <slot name="activator" :on="on">
        <v-btn v-if="icon" icon v-on="on">
          <v-icon>{{ icon }}</v-icon>
        </v-btn>
      </slot>
    </template>
    <v-form ref="form" @submit.prevent="submitForm">
      <v-card>
        <v-card-title v-if="title">
          <span class="headline">{{ title }}</span>
        </v-card-title>
        <v-card-text>
          <v-alert :value="error" transition="scale-transition">
            {{ error }}
          </v-alert>
          <slot :item="value"></slot>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">Close</v-btn>
          <v-btn v-if="!saveInProgress" color="blue darken-1" text type="submit">
            {{ submitText }}
          </v-btn>
          <v-btn v-else color="blue darken-1" text disabled>
            <v-progress-circular indeterminate></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogForm",
  components: {},
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    submit: {
      type: Function,
      required: false,
      default: () => null,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
    maxWidth: {
      type: String,
      required: false,
      default: "300px",
    },
    submitText: {
      type: String,
      required: false,
      default: "Save",
    },
  },
  data() {
    return {
      dialog: false,
      saveInProgress: false,
      error: null,
    };
  },
  computed: {
    form() {
      return this.$refs.form;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.$emit("open");
      } else {
        this.$emit("close");
      }
    },
  },
  methods: {
    submitForm() {
      if (this.form.validate()) {
        this.saveInProgress = true;
        return this.submit()
          .then(() => {
            this.saveInProgress = false;
            this.error = null;
            this.form.resetValidation();
            this.closeDialog();
          })
          .catch((err) => {
            this.saveInProgress = false;
            this.error = err;
          });
      }
      return null;
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style></style>
